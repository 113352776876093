import axios from "axios";
import store from "../store";
import { Toast } from "vant";
import { getHashParam } from "@/utils/utils.js";
import encrypt from "@/utils/encrypt.js";

const key = "NY83ekL2xPuucunRMVWe5mThijh36i"; // 一串固定密文
let appId;
if (window.location.origin == "https://mapi.xydata.cc") {
  appId = "wxb534916b2437f882";
} else {
  appId = getHashParam("openId") || sessionStorage.getItem("openId");
}

if (!sessionStorage.getItem("openId") && appId) {
  sessionStorage.setItem("openId", appId);
}
const password = encrypt.sha1(encrypt.md5.hexMD5(appId + key));

let token = {
  account: appId,
  password,
};

console.log(appId, "appId---");

const header = {
  timeout: 200000,
  headers: {
    // companyCode: 'mh',
    // mobileType,
    // token: sessionStorage.getItem('token'),
    // defroomid: JSON.parse(sessionStorage.getItem('user')) ? JSON.parse(sessionStorage.getItem('user')).defRoomId : '',
    // defcommunityid: sessionStorage.getItem('communityId'),
    // companycode: 'ss',
    ...token,
  },
};
function startLoading() {
  let cancelToastLoading = sessionStorage.getItem("cancelToastLoading");
  if (cancelToastLoading) {
    /* 如果cancelToastLoading存在值，则请求接口时自定义加载逻辑
     * 已使用的该逻辑的模块包括：业主投票 futureTreat/householdVote
     */
    return;
  }
  Toast.loading({
    duration: 0,
    message: "加载中...",
    forbidClick: true,
    loadingType: "spinner",
  });
}
function endLoading() {
  let cancelToastLoading = sessionStorage.getItem("cancelToastLoading");
  if (cancelToastLoading) {
    /* 如果cancelToastLoading存在值，则请求接口时自定义加载逻辑
     * 已使用的该逻辑的模块包括：业主投票 futureTreat/householdVote
     */
    return;
  }
  Toast.clear();
}
const _axios = axios.create(header);

_axios.interceptors.request.use(
  (config) => {
    console.log(config, "config---");
    startLoading();
    let defOrgId = store.state.defOrgId;
    config.headers = Object.assign({ defOrgId }, config.headers);
    if (config.isQinxue) {
      delete config.headers.account;
      delete config.headers.password;
      config.headers.Authorization = "Basic c3dvcmQ6c3dvcmRfc2VjcmV0";
    } else {
    }

    if (config.params) {
      for (let key in config.params) {
        if (typeof config.params[key] == "string") {
          if (
            config.params[key].trim().length == 0 ||
            config.params[key] == "undefined" ||
            config.params[key] == 0
          ) {
            config.params[key] = null;
          }
        }
      }
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

_axios.interceptors.response.use(
  async (res) => {
    endLoading();
    if (res.status != 200) {
      return Promise.reject(res);
    } else {
      if (res.data.code != 200 && res.msg == "您还没有报名该活动！") {
        Toast("请求错误！");
        return res.data;
      }
      if (res.data.code != 200 && res.data.code != 0) {
        if (res.data.msg) {
          Toast(res.data.msg);
        }
      }
    }
    return res.data;
  },
  (error) => {
    endLoading();
    Toast("服务器走丢了");
    return error;
  }
);

export default _axios;
